@import 'styles/abstracts/mixins';

.ReuseForm {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 2rem;
    align-items: flex-end;
    width: 100%;

    .input__container {
        margin: 0;
    }

    &__input {
        width: 100% !important;

        @include res-tablet {
            &-small {
                width: 78px;
                max-width: 78px;
            }

            &-fullWidth {
                width: 100%;
                max-width: 950px;
            }

            &-date {
                width: 180px;
                .input__fecha {
                    min-width: unset;
                }
            }
        }
    }

    &__observations {
        width: 100% !important;
    }

    &__break {
        display: none;
        @include res-bigdesk {
            display: block;
            flex: 1 1 100%;
            height: 0;
        }

        &--always {
            display: block;
            flex: 1 1 100%;
            height: 0;
        }
    }
}
