@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TraceabilitiesTable {
    $this: &;

    width: 100%;
    background-color: white;
    border-collapse: separate; // border-radius wont work on border-collapse: collapse
    border: 1px solid $border-grey;
    border-radius: $default-border-radius;
    border-spacing: 0px;
    color: $text-grey-light;
    font-size: 1.4rem;
    font-weight: 300;

    td {
        padding: 1.5rem; // we have display: flex on mobile, so having more spacing between cells looks cleaner

        @include res-desk {
            padding: 1.5rem 0.5rem; // concrete resolution when we still have a display: table-row and data its too tight -> we reduce horizontal padding
        }

        @include res-bigdesk {
            padding: 1.5rem; // get that extra spacing again
        }

        &:first-of-type {
            padding-left: 1.5rem !important; // always same padding at the left of the table
        }
        &:last-of-type {
            padding-right: 1.5rem !important; // always same padding at the right of the table
        }
    }

    tr {
        position: relative;
        width: 100%;
        -webkit-box-shadow: 0px 1px 0px 0px $border-grey;
        -moz-box-shadow: 0px 1px 0px 0px $border-grey;
        box-shadow: 0px 1px 0px 0px $border-grey;
        cursor: pointer;
        transition: all 0.1s linear;

        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-right: 3rem; // to avoid overlap with actions button on mobile

        @include res-desk {
            padding: 0rem;
            display: table-row;
        }

        &:hover {
            box-shadow: $default-box-shadow;
        }

        &:first-of-type {
            td:first-of-type {
                border-top-left-radius: $default-border-radius;
            }
            td:last-of-type {
                border-top-right-radius: $default-border-radius;
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.4rem;
    }

    &__results {
        font-size: 1.4rem;
        padding-left: 0.4rem;
        color: $blue;
    }

    &__genericDataField {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        font-size: 1.3rem;

        &.--empty {
            opacity: 0.25;
        }

        span {
            color: $text-grey-light;
            max-height: 24.5px;
            min-width: 0;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        svg {
            min-width: 18px;
            width: 18px;
            height: 18px;
            stroke-width: 1.5;
        }
    }

    &__metadata {
        max-width: 120px;

        .Tooltip {
            width: auto;
        }
    }

    &__metadata-tooltip-label {
        display: flex;
        align-items: center;
        gap: 0.4rem;

        #{$this}__status {
            bottom: 0px;
        }
    }

    &__metadata-Date {
        font-weight: 500;
        font-variant-numeric: tabular-nums;
        color: $text-grey-dark;
        text-transform: capitalize;

        display: flex;
        align-items: center;
        gap: 4px;
    }

    &__metadata-displayed-value {
        color: $text-grey-light;
        font-size: 1.3rem;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &--nd {
            opacity: 0.5;
        }
    }

    &__status {
        width: 10px;
        height: 10px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        // width: 0.8rem;
        // height: 0.8rem;
        border-radius: 50%;
        position: relative;
        bottom: 1.5px;
        filter: saturate(2);
        transform: scale(0.85);

        svg {
            color: white !important;
        }

        circle {
            color: transparent !important;
        }

        &--PENDING {
            background: #{$purple};
        }
        &--WARNING {
            background: #{$orange-status};
        }
        &--COMPLETED {
            background: #{$green-status};
        }
        &--DENIED {
            bottom: 3px; // align 'X' vertically

            &::after {
                content: '\D7';
                font-size: 4rem;
                font-weight: 300;
                color: #{$red-status};
            }
        }
        &--CANCELED {
            background: #{$red-status};
        }
    }

    td#{$this}__lerInfo {
        max-width: 130px;

        :nth-child(1) {
            font-weight: 500;
            color: $text-grey-dark;
        }

        :nth-child(2) {
            color: $text-grey-light;
            font-size: 1.3rem;

            position: relative;
            display: block;
            max-height: 24.5px; // 23.8 is the height of the line (added some margin just in case)
            overflow: hidden;
            color: transparent;
            word-break: break-all;

            &::after {
                content: attr(title);
                color: $text-grey-light;
                position: absolute;
                top: 0;
                left: 0;
                right: 0; // this one is also neccesary!!
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    td#{$this}__wasteManagerAndCarrier {
        max-width: 270px;

        // ellipsis effect without setting fixed widths: https://stackoverflow.com/a/44452722
        // TODO: extract this to another file? / scss method? --> USE EllipsisText.tsx
        span:first-of-type {
            position: relative;
            display: block;
            max-height: 24.5px; // 23.8 is the height of the line (added some margin just in case)
            overflow: hidden;
            color: transparent;
            word-break: break-all;
            &::after {
                content: attr(title);
                color: $text-grey-light;
                position: absolute;
                top: 0;
                left: 0;
                right: 0; // this one is also neccesary!!
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    &__measure {
        width: fit-content;
        max-width: 100px;
        white-space: nowrap;
    }

    &__valorizationInfoWrapper {
        width: fit-content;

        @include res-desk {
            min-width: 135px;
            max-width: 135px;
        }

        @include res-bigdesk {
            min-width: 130px;
            max-width: 130px;
        }
    }

    &__valorizationInfo {
        width: fit-content;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;

        padding: 0.25rem 1.2rem;
        border-radius: 999px;
        font-size: 1.2rem;
        font-weight: 550;
        white-space: nowrap;

        &-good {
            background-color: $light-green;
            color: $green;
        }

        &-average {
            background-color: $orange-light;
            color: $orange-status;
        }

        &-bad {
            background-color: rgba($red, 0.3);
            color: $red-status;
        }
    }

    td#{$this}__di {
        white-space: nowrap;
        max-width: 90px;
        color: $text-grey-light;

        button {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            &:not(:disabled) {
                color: $purple;
            }
        }

        &--loading {
            @include skeleton-animation($speed: 0.2s);
        }
    }

    td#{$this}__actions {
        min-width: 50px;
        max-width: 50px;
        text-align: right;
        text-align: -moz-right; // required for firefox

        position: absolute;
        right: 0;
        top: 0;
        margin-right: 0.5rem;

        @include res-desk {
            margin: 0rem;
            position: initial;
        }
    }

    &__button {
        background: transparent;
        border: 0;
        line-height: 1;
        appearance: none;
        cursor: pointer;
        transition: all 0.15s linear;
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        font-weight: inherit;
        padding: 0.6rem 0.8rem;
        border-radius: 5px;
        &:hover {
            background: $background-grey;
        }
        &:disabled {
            pointer-events: none;
        }
    }

    &__openActions {
        width: 3.2rem;
        height: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $text-grey;
    }

    &__action {
        display: flex;
        gap: 0.6rem;
        align-items: center;
        line-height: 1;
    }

    &__tooltip {
        height: 1.6rem;
        color: $purple;
        display: flex;
        align-items: center;
    }

    &--is-loading {
        @include skeleton-animation($speed: 0.3s);
    }

    &--removing {
        @include skeleton-animation($speed: 0.3s);
        background: transparentize($red-dark, $amount: 0.9);
        & > * {
            filter: grayscale(1) opacity(0.5);
        }
    }

    &--is-skeleton {
        @include skeleton-animation();

        td {
            height: 76px;
            div {
                white-space: nowrap;
                @include skeleton($letters: 8);
            }
        }
    }
}
