@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

@keyframes button__spinner-rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Esto está aqui porque la clase de abajo, .button, se sobreescribe por el archivo que está en /styles */
.buttonAux {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;
    font-family: 'Be Vietnam Pro', 'sans-serif';
    font-weight: 500;
    border-radius: 1rem;
    font-size: 1.4rem;
    transition: 0.25s all ease;
    border: none;
    width: max-content;

    padding: 0.4rem 1.2rem;
    min-height: 36px;

    @include res-tablet {
        padding: 1rem 2rem;
    }
}

button.buttonAux:not(.button__small) {
    @include res-tablet {
        height: 44px;
    }
}

.uploadPhoto {
    display: flex;
    align-items: center;
    margin-top: 4rem;
    margin-bottom: 2rem;
    position: relative;

    &__input {
        // no usemos display: none; porque si no no es accesible por teclado!!
        width: 0.1px;
        // height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        // z-index: -1;

        &:focus + label {
            // .has-focus + label
            outline: 3px solid $light-blue;
            outline: -webkit-focus-ring-color $light-blue 3px;
        }

        & + label * {
            pointer-events: none;
        }
    }

    & > div {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 2rem;
    }
}

.button {
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: 'Be Vietnam Pro', 'sans-serif';
    font-weight: 500;
    border-radius: $border-radius-buttons;
    font-size: 1.4rem;
    transition: 0.25s all ease;
    border: none;
    width: max-content;

    $this: &;

    &__blue {
        color: white;
        background-color: $blue;
        border: 2px solid $blue;

        &:hover {
            background-color: $blue-cocircular;
        }
    }

    &__disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &__white {
        color: $blue-cocircular;
        background-color: white;
        border: 2px solid hsl(220, 6%, 90%);

        &:hover {
            background-color: rgba(242, 244, 247, 0.6);
        }

        &#{$this}--ghost {
            background-color: transparent;
            border: transparent;
            padding: 1rem 0.4rem;
        }
    }

    &__small {
        font-size: 1.3rem;
        padding: 0.5rem 1.25rem;
        border-radius: 0.75rem;
        border-width: 1px;
        font-weight: 450;
        gap: 0.5rem;

        svg {
            stroke-width: 1.5;
            max-width: 20px;
        }
    }

    &-full {
        width: 100%;
    }

    &__purple {
        background-color: $purple-medium;
        border-color: $purple-medium;
        color: $white;

        &:hover {
            background-color: $purple;
        }
    }

    &__red {
        background-color: $red;
        color: $white;
        border: 1px solid $red;

        &:hover {
            background-color: $red-dark;
            border: 1px solid $red-dark;
        }

        &#{$this}--ghost {
            background-color: transparent;
            color: $red;
        }
    }

    &__green {
        color: white;
        background-color: $medium-green2;
        border: 1px solid $medium-green2;

        &:hover {
            background-color: $medium-green3;
            border: 1px solid $medium-green3;
        }
    }

    &__refuse {
        background-color: $red;
        color: white;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
        }

        svg {
            margin: 0;
            &:hover {
                color: $white;
            }
        }

        &:hover {
            background-color: $red-dark;
        }
    }

    &__buscar {
        background-color: $blue-cocircular-light-3;
        color: white;
        height: 44px;

        &:hover {
            background-color: $blue-cocircular;
        }
    }

    &__back {
        background-color: transparent;
        padding: 0;
        margin-bottom: 1rem;
        color: #626262;

        &:hover {
            color: $grey-dark;
        }
    }

    &__label {
        line-height: 1.6;
        transition: all 0.15s linear;
    }

    &__spinner {
        position: absolute;
        top: calc(50% - 16px);
        left: calc(50% - 16px);
        color: inherit;
        width: 32px;
        height: 32px;
        animation: button__spinner-rotate 1s linear infinite;
    }

    &--link {
        background: transparent;
        border-color: transparent;
        padding: 0.5rem 1.25rem;
    }

    &--is-loading {
        position: relative;

        #{$this}__label,
        & > svg {
            opacity: 0.15;
        }
    }
}
