@import '../../../styles/abstracts/mixins';
@import '../../../styles/abstracts/variables';

.titulo {
    display: flex;
    align-items: center;

    &__h1 {
        font-size: 2.2rem;
        font-weight: 700;
        margin-bottom: 1rem;
        line-height: 2.8rem;

        @include res-desk {
            font-size: 3rem;
        }
    }

    &__h2 {
        font-size: 1.8rem;
        font-weight: 700;
    }

    &__h3 {
        font-size: 1.6rem;
        font-weight: 650;
        line-height: initial;
        display: flex;
        align-items: center;
    }

    &__h4 {
        font-size: 1.6rem;
        font-weight: 500;
        display: flex;
        align-items: center;
    }

    &__number {
        font-size: 2.5rem;
        font-weight: 650;
    }

    &__unit {
        margin-left: 0.75rem;
        font-size: 2rem;
        font-weight: 650;
    }

    &__promocion {
        font-size: 1.8rem;
        margin: 1rem 0rem;
        font-weight: 650;
    }

    &__danger {
        color: $red-dark;
    }

    & svg {
        margin-left: 0.5rem;
        stroke: $tooltip-grey;
    }
}

.create-button {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    & > h1 {
        margin-bottom: 0rem;
    }

    @include res-desk {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.create-button__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    button {
        margin-bottom: 1rem;
    }

    @include res-desk {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        button {
            margin-left: 1rem;
            margin-bottom: 0;
        }
    }
}
