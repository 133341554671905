@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.metricas {
    display: flex;
    width: fit-content;
    margin: 2rem 0rem;

    &__icon {
        background-color: #d1f0da;
        align-items: center;
        border-radius: 50%;
        display: flex;
        font-size: 0.95rem;
        font-weight: 650;
        justify-content: center;
        padding: 1rem;
        margin-right: 1.75rem;
    }

    span {
        color: #828487;
        font-size: 1.4rem;
    }

    p {
        font-size: 2.5rem;
        font-weight: 650;
    }
}

.card__costes {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;

    @include res-desk {
        flex-direction: row;
    }

    .card__rounded {
        width: 12rem;
        justify-content: center;
    }

    .costes__info {
        display: flex;
        flex-direction: column;
        @include res-desk {
            flex-direction: row;
        }
    }

    .costes__buttons {
        display: flex;
        align-items: center;
    }

    .button__small {
        margin-right: 0.5rem;
    }

    .costes__text {
        @include res-desk {
            margin-left: 2rem;
        }
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .costes__smallText {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
    }
}
