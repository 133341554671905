@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.popover {
    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        gap: 2.4rem;

        & > h2 {
            @include res-desk {
                white-space: nowrap;
            }
        }

        & > svg {
            cursor: pointer;
            min-width: 34px;
        }
    }

    &__container {
        ol li {
            list-style-position: inside;
        }

        &-gestora {
            @include res-desk {
                min-width: 80rem;
            }
        }
    }

    &__innerContainer {
        max-width: 800px;
    }

    &__paragraph {
        margin: 1.5rem 0;
    }

    &__sectionTitle {
        margin-top: 2.5rem;
        margin-bottom: 1rem;
    }

    &__dates {
        margin: 3rem 0rem;

        @include res-desk {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            & > div:nth-child(2) {
                margin: 0rem 2rem;
            }
        }
    }

    &__data {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 2rem 0rem;

        @include res-bigdesk {
            flex-wrap: nowrap;
        }

        & > div {
            margin-right: 3rem;
            &:last-child {
                margin-right: 0rem;
            }
        }
    }

    &__info {
        margin: 2rem 0rem;
    }

    &__submitButton {
        margin: 2rem 0rem;
    }
    &__table {
        margin-top: 1rem;
        border: 1px solid rgb(161, 161, 161);
        border-collapse: collapse;
        border-spacing: 1rem;
        & th,
        td {
            border: 1px solid rgb(161, 161, 161);
            padding: 0.6rem;
        }
        & th {
            background-color: $light-purple;
        }
    }

    &__list {
        margin-left: 4rem;

        &__secondary {
            margin-left: 4rem;
        }
    }

    &__impact-calculation {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}

.data {
    &__row {
        margin-bottom: 0.5rem;
        @include res-tablet {
            margin: 0rem;
            display: flex;
        }
    }

    &__label {
        min-width: 16rem;
        font-weight: 500;
    }

    &__value {
        max-width: 25rem;
        margin-right: 0.5rem;
    }

    &__contacts > div {
        padding: 1rem;
        border: 2px solid $grey;
        border-radius: $default-border-radius;
        overflow-y: auto;

        @include res-desk {
            max-height: 110px;
            min-width: 50rem;
        }
    }

    &__contact {
        margin-bottom: 3rem;
        &:last-child {
            margin-bottom: 0rem;
        }
    }
}

.dates {
    &__container {
        display: flex;
        align-items: center;
        margin: 1rem 0rem;

        @include res-desk {
            margin: 2rem 0rem;
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        margin-left: 1.25rem;
    }

    &__label {
        font-size: 1.2rem;
        color: $dark-grey;
    }

    &__value {
        font-size: 1.4rem;
        font-weight: 600;
    }
}

.ppgcl {
    &__container {
        overflow-y: auto;
        border: 2px solid $grey;
        border-radius: $default-border-radius;
        padding: 2rem;
        margin-top: 1rem;

        @include res-desk {
            max-height: 300px;
        }
    }

    &__result {
        margin-bottom: 4rem;
        &:last-child {
            margin-bottom: 1rem;
        }
    }

    &__data {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0.5rem;

        & > div {
            margin-right: 4rem;
            &:last-child {
                margin-right: 0rem;
            }
        }
    }
}

.popoverDonut {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include res-desk {
        flex-wrap: nowrap;
    }

    &__graphic {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: 400px;
        min-height: 200px;
        padding: 1rem 0rem;
        width: 100%;

        @include res-desk {
            width: 500px;
            padding: 0rem 1rem;
        }
    }

    ul {
        overflow-y: auto;
        padding: 2rem;
        border: 2px solid $grey;
        border-radius: $default-border-radius;
        width: 100%;

        @include res-desk {
            width: unset;
            min-width: 400px;
            max-height: 350px;
        }
    }

    li {
        list-style: none;
        font-weight: 650;
        margin-bottom: 1rem;
        margin-top: 2rem;
        font-size: 1.6rem;

        &:first-child {
            margin-top: 0rem;
        }
    }

    &__desglose {
        display: flex;
        font-weight: 400;
        font-size: 1.4rem;
    }

    &__container {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        .icon__circle {
            margin-right: 1rem;
        }
    }

    &__ler {
        display: flex;
        align-items: center;

        .icon__tooltip {
            color: rgba($tooltip-grey, 0.5);
        }
    }
}

.simplePopover {
    background-color: #ffff;
    border: none;
    border-radius: 1.25rem;
    overflow: unset;
    padding: 3rem;
    // height: 85vh;
    max-height: 85vh;
    width: 85vw;

    @include res-desk {
        height: min-content;
        width: auto;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
}

.constructionSiteDatePopover {
    &__saveButton {
        margin-top: 3rem;
    }
}

.popover__promo {
    @include res-desk {
        form {
            width: 50rem;
            .input__autocomplete {
                width: 100%;
            }
        }
    }
}

.popover__big {
    @include res-desk {
        width: 70rem;
    }
}
