@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.NewReuse {
    &__layoutWrapper {
        width: 100%;
        height: 100%;
    }

    .layout__main {
        padding-bottom: 3rem !important;
    }

    $this: &;
    max-width: $default-container-width;
    width: 100%;
    margin: 0 auto;

    &__header {
        margin-top: 1.6rem;
        margin-bottom: 2.4rem;

        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__main {
        &--is-loading {
            #{$this}__form {
                opacity: 0.5;
                pointer-events: none;
            }
        }

        &--has-error {
            #{$this}__form {
                @include error-animation-shake();
            }
        }

        .card {
            padding: 1.6rem;
            @include res-bigdesk {
                padding: 2.4rem;
                padding-right: 0rem;
            }
        }
    }

    &__errorMsg {
        margin-bottom: 1.6rem;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        gap: 3.2rem;

        @include res-bigdesk {
            flex-direction: row;
        }
    }

    &__document {
        flex: 1;

        @include res-tablet {
            min-width: 64rem;
        }
    }

    &__form {
        width: 100%;
        max-width: 100%;

        @include res-bigdesk {
            max-width: 50%;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        gap: 1.6rem;
        margin-top: 1.6rem;
    }
}
